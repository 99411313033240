import { useState, useEffect } from "react";
import './Dashboard.css';
import Heatmap from "./Heatmap";

function Dashboard() {
    const [timers, setTimers] = useState([]);
    useEffect(() => {
    const fetchTimers = async () => {
        try {
        const response = await fetch(`/api/user/timers`, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setTimers(data.timers); // Update state with fetched timers
            console.log(data.timers); // Process and display your timers

        } else {
            console.error('Failed to fetch timers');
        }
        } catch (error) {
        console.error('There was an error:', error);
        }
    };
    fetchTimers();
    }, []); // Empty dependency array means this runs once on component mount

    function calculateDuration(start, end) {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const durationMs = endTime - startTime;
        
        const seconds = Math.floor((durationMs / 1000) % 60);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        
        return `${minutes}m ${seconds}s`;
    }

    // Prepare the heatmap data
    const heatmapData = Array(7).fill().map(() => Array(24).fill(0));
    timers.forEach(timer => {
        const startTime = new Date(timer.start_time);
        const dayOfWeek = startTime.getDay();
        const hourOfDay = startTime.getHours();
        heatmapData[dayOfWeek][hourOfDay] += 1; // Adjust this line if you're measuring duration or another metric
    });

    return (
        <div className="dashboard">
            <h2>Heatmap</h2>
            <Heatmap data={heatmapData} />
            <h2>Logs</h2>
            <ul className="timer-list">
                {[...timers].reverse().map((timer, index) => (
                    <li key={index} className="timer-item">
                        <div><strong>Start Time:</strong> {new Date(timer.start_time).toLocaleString()}</div>
                        <div><strong>End Time:</strong> {new Date(timer.end_time).toLocaleString()}</div>
                        <div><strong>Description:</strong> {timer.description}</div>
                        <div><strong>Duration:</strong> {calculateDuration(timer.start_time, timer.end_time)}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
    }

export default Dashboard;
