import React from 'react';
import HeatMap from 'react-heatmap-grid';

function ActivityHeatMap({ data }) {
  const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);
  const yLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <HeatMap
      xLabels={xLabels}
      yLabels={yLabels}
      data={data}
      squares
      height={30}
      // You can customize colors, onClick actions, etc.
    />
  );
}

export default ActivityHeatMap;
