import React, { useState } from 'react';
import './Setup.css'; // Ensure you have this CSS file for styling

function Setup({ onWorkChange, onBreakChange }) {
  const [workTime, setWorkTime] = useState(25); // Default work time in minutes
  const [breakTime, setBreakTime] = useState(5); // Default break time in minutes

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    // Ensure the values passed are in seconds and converted to numbers
    onWorkChange(Number(workTime) * 60); // Convert minutes to seconds for work time
    onBreakChange(Number(breakTime) * 60); // Convert minutes to seconds for break time
  };

  return (
    <div className="setup-container">
      <h1>Setup Your Timer</h1>
      <form onSubmit={handleSubmit}>
        <div className="setup-field">
          <label htmlFor="workTime">Work Time (minutes): </label>
          <input
            type="number"
            id="workTime"
            value={workTime}
            min="1" // Ensure that users can't enter a negative number
            onChange={(e) => setWorkTime(e.target.value)}
          />
        </div>
        <div className="setup-field">
          <label htmlFor="breakTime">Break Time (minutes): </label>
          <input
            type="number"
            id="breakTime"
            value={breakTime}
            min="1" // Ensure that users can't enter a negative number
            onChange={(e) => setBreakTime(e.target.value)}
          />
        </div>
        <button type="submit" className="setup-submit">Save</button>
      </form>
    </div>
  );
}

export default Setup;
