// FAQ.js
import React from 'react';
import './FAQ.css'; // Make sure to create an FAQ.css file in the appropriate directory

function FAQ() {
  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <div className="faq">
        <h2>What is the Pomodoro Technique?</h2>
        <p>The Pomodoro Technique is a time management method that uses a timer to break work into intervals, traditionally 25 minutes in length, separated by short breaks.</p>

        <h2>How does the 10xTimer app enhance the Pomodoro Technique?</h2>
        <p>10xTomato enhances the traditional Pomodoro Technique with Language Model recommendations, helping users to analyze their productivity patterns and suggesting optimizations.</p>

      </div>
    </div>
  );
}

export default FAQ;
