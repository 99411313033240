import { useState, useEffect, useCallback } from 'react';

const useTimer = (initialWorkDuration, initialBreakDuration) => {
    const [mode, setMode] = useState('Work');
    const [isActive, setIsActive] = useState(false);
    const [seconds, setSeconds] = useState(initialWorkDuration);
    const [startTime, setStartTime] = useState(null);

    const toggleMode = useCallback(() => {
        const newMode = mode === 'Work' ? 'Break' : 'Work';
        setMode(newMode);
        setIsActive(false);
        setStartTime(null);
        setSeconds(newMode === 'Work' ? initialWorkDuration : initialBreakDuration)
    }, [mode, initialWorkDuration, initialBreakDuration]);

    const toggleTimer = useCallback(() => {
        setIsActive(!isActive);
        if (!isActive && mode === 'Work') {
            setStartTime(new Date());
        }
    }, [isActive, mode]);
    
    const stopTimer = useCallback(() => {
        setIsActive(false);
        setSeconds(mode === 'Work' ? initialWorkDuration : initialBreakDuration);
        setStartTime(null);
    }, [mode, initialWorkDuration, initialBreakDuration])

    useEffect(() => {
        let interval = null;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (!isActive && seconds <= 0) {
            toggleMode();
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, toggleMode]);

    return { mode, toggleMode, isActive, toggleTimer, seconds, stopTimer, setSeconds, startTime }

};

export default useTimer;