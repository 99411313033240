import styles from './Todo.module.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const Todo = ({ username }) => {
    const [tasks, setTasks] = useState([]);
    const [newTaskText, setNewTaskText] = useState('');
    const [sessionId, setSessionId] = useState(uuidv4());

    // asynchronous func to wait for api
    const fetchTasks = async () => {
        try {
            const response = await axios.get('/api/todo');
            setTasks(response.data.tasks);
        } catch (error) {
            console.error('Failed to fetch tasks:', error);
        }
    };

    const addNewTask = async () => {
        if (!newTaskText.trim()) return;
        
        try {
            const newTask = {
                session_id: sessionId,
                user_id: username,
                tasktest: newTaskText,
                is_task_done: false,
                priority: 1,
            };

            const response = await axios.post('/api/todo', newTask);
            setTasks([...tasks, response.data.task]);
            setNewTaskText('');
            setSessionId(uuidv4());
        } catch (error) {
            console.error('Failed to save the new task:', error);
        }
    };

    const toggleTaskStatus = async (id) => {
        if (typeof id === "undefined") {
            console.error('Task ID is undefined, cannot toggle status');
            return;
        }
        const task = tasks.find(task => task.id === id);
        if (!task) return;

        const updatedTaskData = {
            ...task,
            Status: !task.is_task_done,
        };

        try {
            await axios.put(`/api/todo/${id}`, updatedTaskData);
            setTasks(tasks.map(task => task.id === id ? { ...task, is_task_done: !task.is_task_done } : task));
        } catch (error) {
            console.error('Failed to update task status:', error);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, []);

    return (
        <div class={styles.container}>
            <div className={styles.dashboard}>
                <div className={styles.inputField}>
                    <input
                        className={styles.input}
                        type="text"
                        value={newTaskText}
                        onChange={(e) => setNewTaskText(e.target.value)}
                        placeholder="Enter a new task"
                    />
                    <button className={styles.addButton} onClick={addNewTask}>Add Task</button>
                </div>
                <div className={styles.taskListHeader}>
                    <div className={styles.taskListHeaderItem}>Task</div>
                    <div className={styles.taskListHeaderItem}>Status</div>
                    <div className={styles.taskListHeaderItem}>Priority</div>
                </div>
                <ul className={styles.taskList}>
                    {tasks.filter(task => !task.is_task_done).map(task => (
                        <li key={task.id} className={styles.taskItem}>
                            <div className={styles.taskItemCheckbox}>
                                <input
                                    type="checkbox"
                                    onChange={() => toggleTaskStatus(task.id)}
                                />
                            </div>
                            <div className={styles.taskItemDetail}>{task.tasktest}</div>
                            <div className={styles.taskItemDetail}>{task.is_task_done ? 'Done' : 'To do'}</div>
                            <div className={styles.taskItemDetail}>{task.priority}</div>
                        </li>
                    ))}
                    {tasks.some(task => task.is_task_done) && <hr className={styles.divider} />}
                    {tasks.filter(task => task.is_task_done).map(task => (
                        <li key={task.id} className={`${styles.taskItem} ${styles.taskItemDone}`}>
                            <div className={styles.taskItemDetail}>{task.tasktest}</div>
                            <div className={styles.taskItemDetail}>Done</div>
                            <div className={styles.taskItemDetail}>{task.priority}</div>
                        </li>
                    ))}
                </ul>

            </div>
        </div>
    );
};

export default Todo;