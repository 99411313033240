import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import './Pomodoro.css';
import { ReactComponent as PlayerPause } from '../assets/icons/player-pause.svg';
import { ReactComponent as PlayerPlay } from '../assets/icons/player-play.svg';
import { ReactComponent as PlayerStop } from '../assets/icons/player-stop.svg';
import { useTheme } from '../ThemeContext';
import useTimer from './useTimer';

const Pomodoro = ({ workDuration = 1500, breakDuration = 300 , username}) => {
  const { theme } = useTheme();
  const { mode, toggleMode, isActive, toggleTimer, seconds, stopTimer, setSeconds, startTime } = useTimer(workDuration, breakDuration);
  const [description, setDescription] = useState("");
  const [sessionId, setSessionId] = useState(uuidv4());
  const [errorMessage, setErrorMessage] = useState("");

  const sendPostRequest = useCallback(() => {
    if (!startTime) {
      setErrorMessage("Please start the timer first");
      return;
    }
    if (!username) {
      setErrorMessage("You must be logged in to log a work session");
      return;
    }
    if (mode === "Work") {
      const endTime = new Date().toISOString();
      axios.post(`/api/timer`, {
        session_id: sessionId,
        start_time: startTime.toISOString(), 
        end_time: endTime,
        user_id: username,
        description: description,
      })
      .then(response => {
	      console.log(response.data);
	      // Actions after successfully logging the session
	      setDescription("");
	      setSessionId(uuidv4());
	      setSeconds(workDuration);
        stopTimer();
        setErrorMessage("");
      })
      .catch(error => console.error('There was an error:', error));
    }
  }, [sessionId, username, description, mode, workDuration, startTime, stopTimer, setSeconds]);

  // Format the time display
  const formatTime = () => `${Math.floor(seconds / 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

  return (
    <div className={`timer-container ${theme}`}>
      <h2>{mode} Mode</h2>
      <div className="timer-display">{formatTime()}</div>
      <div className="timer-controls">
        <button onClick={toggleTimer} title="Start/Pause">{isActive ? <PlayerPause /> : <PlayerPlay />}</button>
        <button onClick={stopTimer} title="Stop"><PlayerStop /></button>
        <button onClick={toggleMode} title="Toggle Mode">Switch Mode</button>
      </div>
      {mode === 'Work' && (
        <>
          <textarea
            className="description-textarea"
            placeholder="Describe this work session"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <button className="ai-send-button" onClick={sendPostRequest}>Log the work session</button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default Pomodoro;
