import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { useTheme } from '../../ThemeContext'; 
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';

function Login() {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { setUsername } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/login`, { email, password });
      localStorage.setItem('token', response.data.token); // Save token

      setUsername(response.data.username);
      setMessage('Login successful');
      setTimeout(() => {
        navigate('/');
        window.location.reload();
        }, 1000);
      
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <div className={`login-container ${theme}`}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          className='input-field'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className='input-field'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button className='login-button' type="submit">Login</button>
      </form>
      {message && <p className='message'>{message}</p>}
    </div>
  );
}

export default Login;
