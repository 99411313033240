// Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="App-footer">
      <p>&copy; <script>document.write(new Date().getFullYear())</script> 10xtimer. All rights reserved.</p>
      <div className="footer-links">
        <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a>
      </div>
      <p><a href="mailto:contact@10xtimer.com">contact@10xtimer.com</a> | <a href="https://twitter.com/10xtimer">Twitter</a></p>
    </footer>

  );
}

export default Footer;
