import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // Ensure the CSS file is correctly imported
import { useTheme } from '../../ThemeContext'; 
import { useNavigate } from 'react-router-dom';

function Register() {
  const { theme } = useTheme(); // Use theme for conditional class naming
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/register`,
        {
        email,
        password,
        username,
      });

      setMessage(response.data.message);
      setTimeout(() => navigate('/login'), 1000);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <div className={`register-container ${theme}`}> {/* Apply theme and container styles */}
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="input-field" // Apply input styling
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className="input-field" // Apply input styling
          type="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <input
          className="input-field" // Apply input styling
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button className="login-button" type="submit">Register</button> {/* Reuse button styling from Login.css or define new one */}
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default Register;
