// About.js
import React from 'react';
import './About.css'; // Make sure to create an About.css file in the appropriate directory

function About() {
  return (
    <div className="about-container">
      <h1>About 10x</h1>
      <p>I am a software enthusiast who uses pomodoro timers a lot.</p>
      <p>My goal is to provide a simple yet powerful Pomodoro app.</p>
      <p>Upon logging your pomodoro activity over a few weeks, the app is able to give you recommendations on how to improve your approach.</p>
      <p>Welcome to 10x!</p>
      <p>-AKI</p>
    </div>
  );
}

export default About;
